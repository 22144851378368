import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import { SEO } from "../components/seo"

import transformers from "../images/transformers-1.jpg"
import transformersFull from "../images/transformers-2.jpg"

const transformersMag = () => {
  return (
    <div>
         <Layout>
              <div className="container page-width-medium center projects">
                 
                  <h2>Transformers Magazine </h2>
                  <h3 className='subtitle'>A News Portal for Engineers</h3>
                  <small class="center">Year: 2022</small>
                  <img src={transformers} alt="Transformers Magazine" />
                  <p className='mt20'>I was approached by the "Transformers" team (not <em>those</em> Transformers, get serious, dude) to redesign their current Wordpress website. A specification required 18 pages to be planned, wireframed, designed, and developed. </p>
                 
                  <p>I was designing in Adobe XD and Photoshop, and development implied the delivery of clean, semantic and optimized CSS/HTML &amp; Javascript code.</p>
                  <p>Have a <a href="https://north-northwest.com/tm/">sneak peak</a> of the coded site.</p>
                 
              </div>       

              <img className='mt50 project-img' src={transformersFull} alt="Transformers Magazine" />

              
              
         </Layout>
    </div>
  )
  }

  export default transformersMag
  export const Head = () => (
    <SEO />
  )
  
  